<template>
  <v-container>
    <form id="reporting-form">
      <v-card color="brown lighten-5">
        <v-card-title>
          <v-icon>mdi-cash-plus</v-icon>Invoice Reporting
          <v-spacer/>
          <v-btn color="primary" v-if="reportSubmitted" @click="clearForm()">New report</v-btn>
        </v-card-title>
        <v-card-text>
          <v-card v-if="!reportSubmitted">
            <v-card-title>Select date range</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="3">
                  <v-menu
                    ref="menustart"
                    v-model="menuStart"
                    :close-on-content-click="false"
                    :return-value.sync="datePickerStart"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="datePickerStart"
                        label="Start date for reporting period"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="datePickerStart" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuStart = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.menustart.save(datePickerStart)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="menuend"
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    :return-value.sync="datePickerEnd"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="datePickerEnd"
                        label="End date for the reporting period"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="datePickerEnd" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuEnd = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.menuend.save(datePickerEnd)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="3">
                  <strong>Quick select:</strong>
                  <br />
                  <v-btn
                    small
                    color="brown white--text"
                    class="mt-5"
                    @click="lastMonthRange()"
                  >Last Month</v-btn>
                  <br />
                  <v-btn
                    small
                    color="brown white--text"
                    class="mt-5"
                    @click="thisMonthRange()"
                  >This Month</v-btn>
                </v-col>
              </v-row>
              <h3 v-if="!endAfterStart">The end date is before the start date. Fix that.</h3>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                :disabled="this.$v.$anyError || !datePickerStart || !datePickerEnd || !endAfterStart"
                @click="submit"
              >submit</v-btn>
            </v-card-actions>
          </v-card>
          <InvoiceReportSummary
            v-if="reportSubmitted"
            class="mt-5"
            :invoices="invoices"
            :startDate="datePickerStart"
            :endDate="datePickerEnd"
          ></InvoiceReportSummary>

          <InvoiceList v-if="reportSubmitted" class="mt-5" title="Invoices for period" :invoices="invoices"></InvoiceList>
        </v-card-text>
      </v-card>
    </form>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import InvoiceList from "@/components/invoice/InvoiceList";
import InvoiceReportSummary from "@/components/invoice/InvoiceReportSummary";
// import { DateTime } from "luxon";

export default {
  mixins: [validationMixin],
  validations: {
    datePickerStart: { required },
    datePickerEnd: { required },
  },
  components: { InvoiceList, InvoiceReportSummary },
  data: () => ({
    reportSubmitted: false,
    select: null,
    datePickerStart: null,
    datePickerEnd: null,
    menuStart: false,
    menuEnd: false
  }),
  computed: {
    invoices() {
      return this.$store.getters.sortedInvoiceReport;
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("Project is required");
      return errors;
    },
    endAfterStart() {
      return this.datePickerStart <= this.datePickerEnd;
    }
  },

  methods: {
      clearForm() {
          this.select = null;
          this.datePickerStart = null;
          this.datePickerEnd = null;
          this.reportSubmitted = false;
      },
    lastMonthRange() {
      let endDate = new Date(); // current date
      endDate.setDate(1); // going to 1st of the month
      endDate.setHours(-1); // going to last hour before this date even started.  ie. last day of the previous month
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      this.datePickerEnd = endDate.toISOString().split("T")[0];
      let startDate = new Date(endDate);
      startDate.setDate(1); // first of the month
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      this.datePickerStart = startDate.toISOString().split("T")[0];
    },
    thisMonthRange() {
      let endDate = new Date();
      this.datePickerEnd = endDate.toISOString().split("T")[0];
      let startDate = new Date();
      startDate.setDate(1); // first of the month
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      this.datePickerStart = startDate.toISOString().split("T")[0];
    },
    getClient() {
      let index = this.projects.findIndex(
        project => project._id == this.select
      );
      let project = this.projects[index];
      return project.client._id;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.$store.dispatch("loadInvoiceReport", {
          start: this.datePickerStart,
          end: this.datePickerEnd,
        });
        this.reportSubmitted = true;
        // this.$router.push("/timetracker");
      } else {
        this.$store.dispatch("setError", {
          data: { message: "Fix the highlighted fields and try again" }
        });
      }
    }
  },
  mounted() {
  }
};
</script>

<style scoped>
</style>