<template>
  <v-card>
    <v-card-title>
      Invoice Report (
      <span class="date-range"
        >{{ getDate(startDate) }} to {{ getDate(endDate) }}</span
      >)
      <v-spacer />
    </v-card-title>
    <v-card-text>
      Total pre-tax:
      <p>
        <strong><MoneyFormat :value="preTaxAmount"></MoneyFormat></strong>
      </p>
      <p>
        Total Sales tax:
        <strong><MoneyFormat :value="taxAmount"></MoneyFormat></strong>
      </p>
      <p>
        Amount outstanding (not yet received):
        <strong><MoneyFormat :value="outStanding"></MoneyFormat></strong>
      </p>
      <h2>
        Total amount invoiced:
        <MoneyFormat :value="totalAmount"></MoneyFormat>
      </h2>
      <v-btn @click="getCSV()">Download CSV File of this Report</v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import { DateTime, Duration } from "luxon";
import MoneyFormat from "vue-money-format";

export default {
  props: ["invoices", "startDate", "endDate"],
  components: {
    MoneyFormat,
  },
  computed: {
    outStanding() {
      let total = 0;
      this.invoices.forEach((invoice) => {
        if (invoice.status == "sent") {
          total = total + parseInt(invoice.totalAmount);
        }
      });
      return total / 100;
    },
    taxAmount() {
      let total = 0;
      this.invoices.forEach((invoice) => {
        if (invoice.status !== "draft") {
          total = total + parseInt(invoice.tax);
        }
      });
      return total / 100;
    },
    preTaxAmount() {
      let total = 0;
      this.invoices.forEach((invoice) => {
        if (invoice.status !== "draft") {
          total = total + parseInt(invoice.amount);
        }
      });
      return total / 100;
    },
    totalAmount() {
      let total = 0;
      this.invoices.forEach((invoice) => {
        if (invoice.status !== "draft") {
          total = total + parseInt(invoice.totalAmount);
        }
      });
      return total / 100;
    },
  },
  methods: {
    csvExportData() {
      let items = [];
      this.invoices.forEach((inv) => {
        items.push({
          Invoice_Number: inv.invoiceNumber,
          Status: inv.status,
          Date: this.csvDate(inv.date),
          Client: inv.clientName,
          Items: this.convertItemsToString(inv.items),
          Amount: parseInt(inv.amount) / 100,
          Tax: parseInt(inv.tax) / 100,
          Total: parseInt(inv.totalAmount) / 100
        });
      });
      return items;
    },
    convertItemsToString(items) {
      let itemsArr = [];
      let count = 1;
      items.forEach((item) => {
        let newString = `(${count}) ${item.name}--${item.description}--${
          parseInt(item.amount) / 100
        } `;
        itemsArr.push(newString);
        count++;
      });
      return itemsArr.join(" ");
    },
    getCSV() {
      this.$store.dispatch("downloadCSV", {
        items: this.csvExportData(),
        fileName: `Invoices_from_${this.csvDate(
          this.startDate
        )}_to_${this.csvDate(this.endDate)}.csv`,
      });
    },
    getDate(dt) {
      let date = DateTime.fromISO(dt);
      return date.toFormat("LLLL d, yyyy");
    },
    csvDate(dt) {
      let date = DateTime.fromISO(dt);
      return date.toISODate();
    },
    duration(entry) {
      let start = DateTime.fromISO(entry.startTime);
      let end = DateTime.fromISO(entry.endTime);
      let diff = end.diff(start).toObject();
      return Duration.fromObject(diff);
    },
  },
};
</script>
<style scoped>
.total-time {
  color: green;
}
.date-range {
  color: gray;
  font-weight: normal;
}

.client-name {
  color: gray;
  font-weight: normal;
  font-size: 95%;
  margin-left: 10px;
}
</style>